/* eslint-disable no-undef */
export default {
    name: 'Utils',
    isEqual: isEqual,
    getEnv: getEnv
}

function isEqual(obj1, obj2) {
    let objKeys1 = Object.keys(obj1);
    let objKeys2 = Object.keys(obj2);

    if (objKeys1.length !== objKeys2.length) {
        return false;
    }

    for (let objKey of objKeys1) {
        if (obj1[objKey] !== obj2[objKey]) {
            return false
        }
    }
    return true;
}
function getEnv(name) {
    return process.env[name]
}
