const DEFAULT_ACCENT = '#8E001C';
const PRIMARY = '#8E001C';
const SECONDARY = '#8E001C';
const DEFAULT_SUBTLE = '#767676'; // With contrast 4.5:1 to white
const PADDING_REGULAR = 12;

function fontFamily(fonts) {
    return fonts.map(font => `'${font}'`).join(', ');
}

export default function styleSet() {
    return {
        "accent":DEFAULT_ACCENT,
        "backgroundColor": 'transparent',
        "subtle": DEFAULT_SUBTLE,
        "paddingRegular": PADDING_REGULAR,
        "paddingWide": PADDING_REGULAR * 2,

        "fontSizeSmall": '100%',
        "monospaceFont": fontFamily(['Montserrat', 'monospace']),
        "primaryFont": fontFamily([ 'Montserrat']),
        "markdownExternalLinkIconImage":'',
        // Avatar
        //"botAvatarImage": './icon.png',
        //"avatarSize": 40,
        //"botAvatarBackgroundColor": "transparent",
        //"botAvatarInitials": "",
        "transcriptOverlayButtonBackground":PRIMARY,
        "transcriptOverlayButtonBackgroundOnHover":PRIMARY,
        "transcriptActivityVisualKeyboardIndicatorStyle": "none",
        //Bubble
        "bubbleMaxWidth": 450,
        "bubbleMinHeight": 40,
        "bubbleMinWidth": 250,
        "bubbleBackground": "#FAF5F3",
        "bubbleBorderRadius": 16,
        "bubbleBorderStyle": 'none',
        "bubbleBorderWidth": 0,
        "bubbleNubOffset": "bottom",
        "bubbleNubSize": 10,
        "bubbleTextColor": '#636B71',

        "bubbleFromUserBackground": PRIMARY,
        "bubbleFromUserBorderRadius": 15,
        "bubbleFromUserTextColor": "#FFFFFF",
        "bubbleFromUserNubOffset": "bottom",
        "bubbleFromUserNubSize": 10,
        //Send Box
        "hideUploadButton": true,
        "hideSendBox": false,
        "microphoneButtonColorOnDictate": PRIMARY,
        "sendBoxBackground": "#FFFFFF",
        "sendBoxButtonColor": PRIMARY,
        "sendBoxBorderTop": "solid 2px #E6E6E6",
        "sendBoxBorderBottom": "solid 2px #E6E6E6",
        "sendBoxBorderLeft": "solid 2px #E6E6E6",
        "sendBoxBorderRight": "solid 2px #E6E6E6",

        "adaptiveCardsParserMaxVersion": undefined,
        "cardEmphasisBackgroundColor": 'rgba(0,159,218,0.15)',
        "cardPushButtonBackgroundColor": PRIMARY,
        "cardPushButtonTextColor": 'White',
        "richCardWrapTitle": false,
        "typingAnimationBackgroundImage": undefined,
        "typingAnimationDuration": 12000,
        "typingAnimationHeight": 20,
        "typingAnimationWidth": 64,

        // toast
        "toasterHeight": 65,
        "toasterMaxHeight": 65 * 5,
        "toasterSingularMaxHeight": 65,
        "toastFontSize": '15px',

        //scroll
        "autoScrollSnapOnPage": true
    }
}
