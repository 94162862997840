import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Routes, useLocation
} from 'react-router-dom';

import WebChat from "./components/WebChat.js"



function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="*" element={<WebChat/>}>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
